'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('wopackage', {
        url: '/wopackage',
        template: '<wopackage></wopackage>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
